import React from 'react';

import classNames from 'classnames';

interface CheckboxProps {
  onClick: (e: any) => void;
  classes?: string;
}

const Checkbox = ({ onClick, classes }: CheckboxProps) => {
  return (
    <label
      className={classNames(
        'mr-8',
        'group',
        'w-[24px]',
        'h-[24px]',
        'relative',
        'block',
        'cursor-pointer',
        'select-none',
        'border-0',
        classes
      )}
    >
      <input type="checkbox" className="w-[0] h-[0] opacity-0 absolute peer" onClick={onClick} />
      <span
        className={classNames(
          'absolute',
          'top-0',
          'left-0',
          'right-0',
          'bottom-0',
          'm-auto',
          'w-[20px]',
          'h-[20px]',
          'rounded-[4px]',
          'bg-volcaneers-checkbox-default',
          'group-hover:bg-volcaneers-checkbox-dark',
          'peer-checked:bg-volcaneers-primary-default',
          [
            'after:content-[""]',
            'after:absolute',
            'after:hidden',
            'after:left-[8px]',
            'after:top-[4px]',
            'after:w-[5px]',
            'after:h-[9px]',
            'after:border',
            'after:border-white',
            'after:border-t-0',
            'after:border-r-2',
            'after:border-b-2',
            'after:border-l-0',
            'after:rotate-45',
            'peer-checked:after:block'
          ]
        )}
      />
    </label>
  );
};

export default Checkbox;
