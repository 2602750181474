import { CheckCircleIcon, PaperAirplaneIcon, XCircleIcon } from '@heroicons/react/outline';
import axios from 'axios';
import classNames from 'classnames';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';

import OvalIllustration from '../../static/images/illustration-oval.inline.svg';

import { AnalyticsEventLocations, AnalyticsEvents } from '../analytic-events';
import * as Analytics from '../services/analytics-utils';
import { getLocalizedLink } from '../utils/locale';

import Loader from './v2/Loader';
import Checkbox from './v2/volcaneers/Checkbox';
import FullwidthIllustration from './v2/volcaneers/FullwidthIllustration';

const SubscribeSection = () => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'rejected'>('idle');
  const [consent, setConsent] = useState(false);

  const isFormValid = email !== '' && email.trim() !== '' && consent;

  const isLoading = status === 'pending';
  const isError = status === 'rejected';
  const isSuccess = status === 'success';

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isFormValid) return;
    setStatus('pending');
    try {
      const response = await axios.post('/api/mailjet-subscription', { email });
      if (response.data?.ErrorInfo) {
        setStatus('rejected');
        return;
      }
      setStatus('success');
    } catch (err) {
      console.error(err);
      setStatus('rejected');
    }
  };

  return (
    <section id="subscribe-section" className="relative bg-engine-green pt-40 lg:pt-80">
      <div className="container-slim relative flex flex-col items-center h-[520px] md:h-[540px]">
        <img
          className="w-full absolute bottom-[15px] hidden lg:block"
          src="/images/paima-engine-home-subscribe-bg.png"
          width="1080"
          height="540"
        />
        <div className="flex flex-col w-full items-center relative z-20">
          <h1 className="text-40 md:text-48 font-heading font-bold mb-16 text-center text-white mt-40">
            <Trans>Subscribe for Updates</Trans>
          </h1>
          <p className="text-16 font-base text-white text-center md:w-[540px]">
            <Trans>We will share only the latest updates, processes, plans and insights.</Trans>
          </p>
          <form noValidate onSubmit={handleSubmit} className="w-full md:w-[440px] mt-56">
            <div className="w-full flex justify-between bg-white rounded-full p-4">
              <label className="hidden" htmlFor="mce-EMAIL">
                <Trans>Email Address</Trans>
              </label>
              <input
                className={classNames(
                  'required',
                  'email',
                  'block',
                  'py-20',
                  'px-30',
                  'w-full',
                  'bg-blue-mail',
                  'rounded-full',
                  'placeholder:text-grey-200',
                  'focus:outline-0'
                )}
                placeholder={t('Your email address')}
                type="email"
                name="EMAIL"
                required
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
              />
              <button
                className="btn-big px-0 md:px-32 shrink-0 w-[64px] flex justify-center items-center md:w-full max-w-[160px] disabled:cursor-not-allowed"
                type="submit"
                onClick={() => {
                  Analytics.event(AnalyticsEvents.SUBSCRIBE, '', AnalyticsEventLocations.HOME_SUBSCRIBE);
                }}
                disabled={!isFormValid || isLoading}
              >
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <span className="hidden md:block">
                      <Trans>Subscribe</Trans>
                    </span>
                    <PaperAirplaneIcon
                      className="h-24 w-24 text-white md:-ml-10 block md:hidden rotate-90"
                      aria-hidden="true"
                    />
                  </>
                )}
              </button>
            </div>
            {!isSuccess ? (
              <div className="mt-16 lg:mt-24 px-24 flex">
                <Checkbox
                  classes="bg-white mr-0 flex items-center justify-center rounded-[4px]"
                  onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setConsent(e.target.checked);
                  }}
                />
                <div className="-mt-2 text-14 leading-5 flex-1 text-white ml-8 pt-2">
                  <Trans
                    i18nKey="I have read, understand and consent to the Terms Of Service and the Privacy Policy."
                    components={[
                      <a
                        href={getLocalizedLink('/terms-of-service', i18n.language)}
                        className="underline"
                        target="_blank"
                      />,
                      <a
                        className="underline"
                        href={getLocalizedLink('/privacy-policy', i18n.language)}
                        target="_blank"
                      />
                    ]}
                  />
                </div>
              </div>
            ) : (
              isSuccess && (
                <div className="w-full md:w-[440px] flex items-center justify-center text-white mt-16">
                  <CheckCircleIcon className="w-20 h-20 mr-6" />
                  <p>
                    <Trans>You have successfully subscribed to our newsletter!</Trans>
                  </p>
                </div>
              )
            )}
          </form>
          {isError && (
            <div className="w-full md:w-[440px] flex items-center justify-center text-white mt-16">
              <XCircleIcon className="w-20 h-20 mr-6" />
              <p>
                <Trans>Ups, something went wrong! Try again.</Trans>
              </p>
            </div>
          )}
        </div>
      </div>
      <FullwidthIllustration
        illustration={<OvalIllustration />}
        containerClasses="pt-[10vw] fill-volcaneers-black"
        classes="absolute left-0 right-0 bottom-0"
      />
    </section>
  );
};

export default SubscribeSection;
