import * as React from 'react';

import CookieConsentBanner from '../components/CookieConsentBanner';
import Footer from '../components/v2/Footer';
import Header from '../components/v2/header/Header';
import Layout from '../components/v2/Layout';
import SubscribeSection from '../components/SubscribeSection';

// markup
const Newsletter = () => {
  return (
    <Layout title="Newsletter">
      <Header showComingSoonCountdown={false} />
      <CookieConsentBanner />
      <SubscribeSection />
      <Footer />
    </Layout>
  );
};

export default Newsletter;
